<template>
	<div class="animated fadeIn">
		<b-card title="Add Maintenance" sub-title="Handles the creation of maintenance">
			<br />
			<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
			<b-form @submit.stop.prevent="handleSubmit" novalidate>
				<div role="tablist">
					<!-- AREA -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion1 variant="primary"
								class="text-sm-left accordion-title">
								PRIMARY DETAILS
							</b-btn>
						</b-card-header>
						<b-collapse v-model="showFirstPane" id="accordion1" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="4" md="6" sm="12">
										<b-row class="my-2">
											<b-col sm="8">
												<b>SELECT AREA</b>
											</b-col>
										</b-row>
										<b-form-group label="Company" label-for="Company">
											<v-select name="Company" class="style-chooser" label="text"
												:options="companyOptions" :reduce="(company) => company.value"
												v-model="selCompany" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a company
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Company')" class="help-block">
												{{ errors.first('Company') }}
											</span>
										</b-form-group>
										<b-form-group label="Storage Location" label-for="Storage Location">
											<v-select name="Storage Location" class="style-chooser" label="text"
												:options="storageLocationOptions" :reduce="(loc) => loc.value"
												v-model="selStorageLocation" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a location
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Storage Location')" class="help-block">
												{{ errors.first('Storage Location') }}
											</span>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="6" sm="12">
										<b-row class="my-2">
											<b-col sm="12">
												<b>SELECT ASSET TYPE</b>
											</b-col>
										</b-row>
										<b-form-group label="Asset Type" label-for="Asset Type">
											<v-select name="Asset Type" class="style-chooser" label="text"
												:options="assetTypesOptions" :reduce="(assetType) => assetType.value"
												v-model="selAssetType" v-validate="'selectRequired'">
												<template v-slot:no-options="{ search, searching }">
													<template v-if="searching">
														No results found for
														<em>
															<strong>{{ search }}</strong>
														</em>
													</template>
													<em :style="{ opacity: 0.5 }" v-else>
														Start typing to search for a user
													</em>
												</template>
											</v-select>
											<span v-show="errors.has('Asset Type')" class="help-block">
												{{ errors.first('Asset Type') }}
											</span>
										</b-form-group>
										<b-form-group label="Description" label-for="Description" description>
											<b-form-textarea name="Description" type="text" v-model="form.description"
												maxlength="200" v-validate="{
													required: true,
													regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
												}" :rows="3" placeholder="Description" />
											<span v-show="errors.has('Description')" class="help-block">
												{{ errors.first('Description') }}
											</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- CLASSIFICATION -->
					<b-card no-body class="mb-1">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion2 variant="primary"
								class="text-sm-left accordion-title">
								CLASSIFICATION
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion2" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row>
									<b-col lg="6" md="6" sm="12">
										<canvas id="classification-chart"></canvas>
									</b-col>

									<b-col lg="6" md="6" sm="12">
										<b-row>
											<b-col lg="8" md="12" sm="12">
												<strong>Total: {{ totalAssetCount }}</strong>
											</b-col>
										</b-row>
										<b-row class="mt-2">
											<b-col lg="8" md="12" sm="12">
												<b-form-group label="For Repair"
													description="Number of candidate assets for repair" label-for="repair">
													<b-form-input name="Repair" type="number" min="0" max="1000"
														v-model="repairCount" placeholder="0" maxlength="50"
														v-validate="'required'" />
													<span v-show="errors.has('Repair')" class="help-block">
														{{ errors.first('Repair') }}
													</span>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col lg="8" md="12" sm="12">
												<b-form-group label="For Disposal"
													description="Number of unrepairable assets for disposal"
													label-for="dipose">
													<b-form-input name="Dipose" type="number" min="0" max="1000"
														v-model="disposedCount" placeholder="0" maxlength="50"
														v-validate="'required'" />
													<span v-show="errors.has('Dipose')" class="help-block">
														{{ errors.first('Dipose') }}
													</span>
												</b-form-group>
											</b-col>
										</b-row>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- DAMAGED ASSETS -->
					<b-card no-body class="mb-1" v-if="disposedCount > 0">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion3 variant="primary"
								class="text-sm-left accordion-title">
								DISPOSAL
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion3" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-2">
									<b-col sm="12">
										<b-form-group>
											<template #description>
												<span style="font-style: italic;">Maximum upload of three (3) photos
													only</span>
											</template>
											<b>UPLOAD PHOTO(S)</b>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col sm="4">
										<b-form-group description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="assets-for-disposal-0" placeholder="Choose image"
												ref="assets-for-disposal-file-input-0" accept="image/png"
												@change="onSelectDisposePhoto($event, 0)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="assets-for-disposal-1" placeholder="Choose image"
												ref="assets-for-disposal-file-input-1" accept="image/png"
												@change="onSelectDisposePhoto($event, 1)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col sm="4">
										<b-form-group description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="assets-for-disposal-2" placeholder="Choose image"
												ref="assets-for-disposal-file-input-2" accept="image/png"
												@change="onSelectDisposePhoto($event, 2)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="disposeMedia1Obj.url">
											<b-card overlay :img-src="disposeMedia1Obj.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDisposePhoto(0)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploadingState[0].status === 'uploading'"
												:value="uploadingState[0].percentage" :max="uploadingState[0].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>

									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="disposeMedia2Obj.url">
											<b-card overlay :img-src="disposeMedia2Obj.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDisposePhoto(1)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploadingState[1].status === 'uploading'"
												:value="uploadingState[1].percentage" :max="uploadingState[1].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>

									<b-col sm="4">
										<!-- Image Preview -->
										<div v-if="disposeMedia3Obj.url">
											<b-card overlay :img-src="disposeMedia3Obj.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveDisposePhoto(2)">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploadingState[2].status === 'uploading'"
												:value="uploadingState[2].percentage" :max="uploadingState[2].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>
								</b-row>

								<b-row class="mt-4">
									<b-col lg="4" md="4" sm="12">
										<b-form-group label="Remarks" label-for="Disposal Remarks" description>
											<b-form-textarea name="Disposal Remarks" type="text"
												v-model="form.disposalRemarks" maxlength="200" v-validate="{
													required: true,
													regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
												}" :rows="3" placeholder="Remarks"></b-form-textarea>
											<span v-show="errors.has('Disposal Remarks')" class="help-block">{{
												errors.first('Disposal Remarks')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>


							</b-card-body>
						</b-collapse>
					</b-card>

					<!-- APPROVAL FORMS -->
					<b-card no-body class="mb-1" v-if="repairCount > 0">
						<b-card-header header-tag="header" class="p-1" role="tab">
							<b-btn block href="#" v-b-toggle.accordion4 variant="primary"
								class="text-sm-left accordion-title">
								APPROVAL FORMS
							</b-btn>
						</b-card-header>
						<b-collapse id="accordion4" accordion="my-accordion" role="tabpanel">
							<b-card-body>
								<b-row class="my-12">
									<b-col lg="4" md="4" sm="12">
										<b-form-group label="Client Approval Form" label-for="client-form"
											description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="client-form" placeholder="Choose image"
												ref="client-form-file-input" accept="image/png"
												@change="onSelectClientApprovalForm($event)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
									<b-col lg="4" md="4" sm="12">
										<b-form-group label="CAPEX Form" label-for="capex-form"
											description="*32-bit PNG, 1000px by 1000px, up to 1 MB">
											<b-form-file id="capex-form" placeholder="Choose image"
												ref="capex-form-file-input" accept="image/png"
												@change="onSelectCapexForm($event)">
												<template slot="file-name" slot-scope="{ names }">
													<b-badge variant="dark">{{ names[0] }}</b-badge>
													<b-badge v-if="names.length > 1" variant="dark" class="ml-1">+ {{
														names.length - 1
													}} More files</b-badge>
												</template>
											</b-form-file>
										</b-form-group>
									</b-col>
								</b-row>
								<b-row class="my-12">
									<b-col lg="4" md="4" sm="12">
										<!-- Image Preview -->
										<div v-if="clientApprovalMediaObj.url">
											<b-card overlay :img-src="clientApprovalMediaObj.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveClientForm()">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploadingState[3].status === 'uploading'"
												:value="uploadingState[3].percentage" :max="uploadingState[3].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>
									<b-col lg="4" md="4" sm="12">
										<!-- Image Preview -->
										<div v-if="capexMediaObj.url">
											<b-card overlay :img-src="capexMediaObj.url" img-alt="Card Image"
												text-variant="white" class="mb-0">
												<b-card-text class="text-right">
													<b-button variant="danger" size="sm" @click="onRemoveCapexForm()">
														<em class="fa fa-trash"></em>
													</b-button>
												</b-card-text>
											</b-card>
											<b-progress v-if="uploadingState[4].status === 'uploading'"
												:value="uploadingState[4].percentage" :max="uploadingState[4].name"
												variant="success" height="8px" animated />
										</div>
									</b-col>
								</b-row>
								<b-row class="mt-4">
									<b-col lg="4" md="4" sm="12">
										<b-form-group label="Remarks" label-for="Approval Form Remarks" description>
											<b-form-textarea name="Approval Form Remarks" type="text"
												v-model="form.approvalFormRemarks" maxlength="200" v-validate="{
													required: true,
													regex: /^([A-Za-z0-9\s:;,.!'\/()-_]{1,200})$/,
												}" :rows="3" placeholder="Remarks"></b-form-textarea>
											<span v-show="errors.has('Approval Form Remarks')" class="help-block">{{
												errors.first('Approval Form Remarks')
											}}</span>
										</b-form-group>
									</b-col>
								</b-row>
							</b-card-body>
						</b-collapse>
					</b-card>
				</div>
			</b-form>
			<b-row>
				<b-col md="12" sm="12" class="my-1 text-sm-right">
					<b-button variant="secondary" @click="returnToMaintenanceMain">
						Cancel
					</b-button>
					&nbsp;&nbsp;
					<b-button variant="primary" @click="handleOk" :disabled="isLoading">
						Save
					</b-button>
				</b-col>
				<b-col md="1" sm="1" class="my-1"></b-col>
			</b-row>
		</b-card>
	</div>
</template>

<script>
// Util
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ImageUtil } from '@/utils/imageUtil';
import { FileUtil } from '@/utils/fileUtil';

// API
import maintenanceApi from '@/api/maintenanceApi';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { storage } from '@/config/firebase';
import Chart from 'chart.js';

export default {
	name: 'add-maintenance',
	components: {
		Loading,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'batchNo',
					label: 'Batch No.',
				},
				{
					key: 'repaired',
					label: 'Repaired',
					sortable: true,
				},
				{
					key: 'checked',
					label: 'Checked',
					sortable: true,
				},
				{
					key: 'endorsed',
					label: 'Endorsed',
					sortable: true,
				},
				{
					key: 'status',
				},
				{
					key: 'remarks',
				},
				'actions',
			],

			currentPage: 1,
			perPage: 10,
			totalRows: 0,
			pageOptions: [5, 10, 15, 25, 50, 100],
			sortBy: null,
			sortDesc: false,
			sortDirection: 'asc',
			filter: null,

			totalRepaired: 0,
			totalChecked: 0,
			totalEndorsed: 0,
			totalAssetCount: 0,

			repairCount: 0,
			disposedCount: 0,

			form: {
				maintenanceId: '',
				company: '',
				companyId: '',
				storageLocation: '',
				storageLocationId: '',
				assetType: '',
				assetTypeId: '',
				repairCount: 0,
				disposedCount: 0,
				repairedBatches: [],
				disposals: [],
				disposalRemarks: '',
				clientApprovalForm: null,
				capexForm: null,
				approvalFormRemarks: '',
				status: '',
				description: '',
				dateCreated: '',
				createdBy: '',
			},

			showFirstPane: true,

			disposeMedia1Obj: {},
			disposeMedia2Obj: {},
			disposeMedia3Obj: {},
			clientApprovalMediaObj: {},
			capexMediaObj: {},
			uploadingState: [],

			companyOptions: [],
			storageLocationOptions: [],
			selMaintenance: {},
			currLocation: null,

			// Params from Dispatch
			params: {},
			assetTypesOptions: [],
			allCompaniesObj: {},
			allStorageLocationsObj: {},
			allConnectionsObj: {},
			allUsersObj: {},
			allAssetTypesObj: {},

			selCompany: config.companyDefaultValue,
			selStorageLocation: config.storageLocationDefaultValue,
			selAssetType: config.assetTypeDefaultValue,

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			chart: null,

			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		allCompaniesObj: function () {
			if (this.isSuperAdmin) {
				this.companyOptions = DropDownItemsUtil.retrieveActiveCompanyItems(
					this.allCompaniesObj
				);
			} else {
				this.companyOptions = DropDownItemsUtil.retrieveConnectedCompanyItems(
					this.allConnectionsObj, this.loggedUserCompany
				);
			}
		},

		selCompany: function () {
			this.onChangeCompany();
		},

		repairCount: function () {
			this.onChangeClassification();
		},

		disposedCount: function () {
			this.onChangeClassification();
		},
	},

	beforeMount() {
		for (let i = 0; i < 5; i++) {
			this.uploadingState[i] = {
				name: '',
				percentage: 0,
				status: '', // [uploading, success, error]
			};
		}
	},

	mounted() {
		// Don't initiate data retrieval when the account is not authenticated
		if (!this.$store.getters.isAuthenticated) {
			return;
		}

		this.getCurrentLocation();

		// show loading indicator
		this.isLoading = true;

		setTimeout(() => {
			// init parameters
			this.params = this.$store.getters.maintenanceParams;

			// Objects
			this.allCompaniesObj = this.params.allCompaniesObj;
			this.allStorageLocationsObj = this.params.allStorageLocationsObj;
			this.allConnectionsObj = this.params.allConnectionsObj;
			this.allUsersObj = this.params.allUsersObj;
			this.allAssetTypesObj = this.params.allAssetTypesObj;

			// reset options
			this.companyOptions = DropDownItemsUtil.retrieveCompanyItems(
				this.allCompaniesObj, this.loggedUserCompany
			);

			this.assetTypesOptions = DropDownItemsUtil.retrieveAssetTypeItems(
				this.allAssetTypesObj
			);

			// reset all area fields
			this.resetStorageLocationOptions();

			this.onReset();

			const ctx = document.getElementById('classification-chart');

			this.chart = new Chart(ctx, {
				type: 'pie',
				data: {
					labels: ['For Disposal', 'For Repair'],
					datasets: [
						{
							data: [50, 50],
							borderWidth: 1,
							backgroundColor: ['rgb(241, 143, 1)', 'rgb(18, 43, 145)'],
						},
					],
				},
				options: {
					scales: {
						y: {
							beginAtZero: true
						}
					},
					legend: {
						position: 'top',
					},
					title: {
						display: true,
						text: 'Classification'
					}
				},
			});

			// hide loading indicator
			this.isLoading = false;
		}, config.timeout);
	},
	methods: {
		async getCurrentLocation() {
			try {
				this.currLocation = await this.$getCurrentLocation();
			} catch (error) {
				this.$toaster.error(
					'Error loading data. Please reload the page again.'
				);
			}
		},
		resetStorageLocationOptions() {
			this.storageLocationOptions = [];
			this.storageLocationOptions.push({
				value: config.storageLocationDefaultValue,
				text: ' - Please select - ',
			});

			this.selStorageLocation = config.storageLocationDefaultValue;
		},

		onChangeCompany() {
			// reset storage fields
			this.resetStorageLocationOptions();

			if (this.selCompany !== config.companyDefaultValue) {
				// retrieve the storage locations
				this.storageLocationOptions =
					DropDownItemsUtil.retrieveStorageLocationOfConnectionItems(
						this.allConnectionsObj,
						this.allStorageLocationsObj,
						this.selCompany.id,
						this.selCompany.id
					);

				// pre-select the single option value
				if (this.storageLocationOptions.length == 2) {
					this.selStorageLocation = this.storageLocationOptions[1].value;
				}
			}
		},

		onChangeClassification() {
			// Parse input values
			this.disposedCount = parseInt(this.disposedCount);
			this.repairCount = parseInt(this.repairCount);

			// Calculate total asset count
			this.totalAssetCount = this.disposedCount + this.repairCount;

			// Update form values
			this.form.disposedCount = this.disposedCount;
			this.form.repairCount = this.repairCount;

			// Update chart data
			this.chart.data.datasets[0].data = [this.disposedCount, this.repairCount];
			this.chart.update();
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();
			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input');
				this.isLoading = false;
				return;
			}

			let isValidClassificationCount = (this.disposedCount + this.repairCount) > 0;
			if (!isValidClassificationCount) {
				this.$toaster.warning('Please address the field/s with invalid input: Repair and disposal must not be both zero.');
				this.isLoading = false;
				return;
			}

			this.form = this.processMaintenanceForm();
			await this.handleSubmit();

			// hide loading indicator
			this.isLoading = false;
		},

		async handleSubmit() {
			let maintenanceId = this.form.maintenanceId;
			let currUserId = this.loggedUser.id;

			try {
				// run the add maintenance api
				let result = await maintenanceApi.addMaintenance(this.form, currUserId);
				let data = result.data;

				if (data.isSuccess) {
					// upload document forms
					await this.uploadDocuments(data.maintenance);

					this.$toaster.success(
						`New maintenance "${maintenanceId}" was created successfully.`
					);

					EventBus.$emit('onCloseAddMaintenance', data.maintenance);

					// reset the state and revert to maintenance page
					this.returnToMaintenanceMain();
				} else {
					this.$toaster.warning(data.message);
				}

			} catch (error) {
				this.$toaster.error(
					`Error creating maintenance ${maintenanceId}. Please try again.`
				);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		async uploadDocuments(maintenance) {

			for (let i = 0; i < 3; i++) {
				let disposeMediaObj = this.getDisposeMediaObj(i);
				if (disposeMediaObj && disposeMediaObj.isNew) {
					const result = await this.firebaseUploadImage(
						disposeMediaObj,
						0
					);
					if (result.name && result.url) {
						maintenance.disposals[i].url = result.url;
						const updateResult = await maintenanceApi.updateMaintenance(
							maintenance,
							this.loggedUser.id
						);
						if (updateResult.isSuccess) {
							this.onUploadingInProgress(i, 'success');
						}
					}
				}
			}

			if (this.clientApprovalMediaObj && this.clientApprovalMediaObj.isNew) {
				const result = await this.firebaseUploadImage(
					this.clientApprovalMediaObj,
					3
				);
				if (result.name && result.url) {
					maintenance.clientApprovalForm.url = result.url;
					const updateResult = await maintenanceApi.updateMaintenance(
						maintenance,
						this.loggedUser.id
					);
					if (updateResult.isSuccess) {
						this.onUploadingInProgress(3, 'success');
					}
				}
			}

			if (this.capexMediaObj && this.capexMediaObj.isNew) {
				const result = await this.firebaseUploadImage(
					this.capexMediaObj,
					4
				);
				if (result.name && result.url) {
					maintenance.capexForm.url = result.url;
					const updateResult = await maintenanceApi.updateMaintenance(
						maintenance,
						this.loggedUser.id
					);
					if (updateResult.isSuccess) {
						this.onUploadingInProgress(4, 'success');
					}
				}
			}
		},

		async firebaseUploadImage(mediaFile, index) {
			this.onUploadingInProgress(index, 'uploading', mediaFile.name, 0);

			return new Promise((resolve, reject) => {
				let storageRef = storage.ref(`${mediaFile.fbStoragePath}/${mediaFile.name}`);
				let task = storageRef.put(mediaFile.file);

				task.on(
					'state_changed',
					(snapshot) => {
						let percentage =
							(snapshot.bytesTransferred / snapshot.totalBytes) * 100;
						this.onUploadingInProgress(index, 'uploading', mediaFile.name, percentage);
					},
					(error) => {
						reject(error);
						this.onResetUploadingState(index);
					},
					() => {
						task.snapshot.ref.getDownloadURL().then((downloadURL) => {
							resolve({
								name: mediaFile.name,
								url: downloadURL,
							});
						});
					}
				);
			});
		},

		processMaintenanceForm() {
			// Update Maintenance Fields
			this.form.maintenanceId = 'MS' + DateUtil.getCurrentTimestamp();
			this.form.dateCreated = DateUtil.getCurrentTimestamp();
			this.form.createdBy = this.loggedUser.id;
			this.form.dateUpdated = DateUtil.getCurrentTimestamp();
			this.form.updatedBy = this.loggedUser.id;

			this.updateArea();
			this.updateClassification();
			this.updateDocuments();

			return this.form;
		},

		updateArea() {
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;
			this.form.storageLocation = this.selStorageLocation.name;
			this.form.storageLocationId = this.selStorageLocation.id;
			this.form.assetType = this.selAssetType.name;
			this.form.assetTypeId = this.selAssetType.id;
		},

		updateClassification() {
			this.form.repairCount = this.repairCount;
			this.form.disposedCount = this.disposedCount;
		},

		updateDocuments() {
			for (let i = 0; i < 3; i++) {
				let disposMediaObj = this.getDisposeMediaObj(i);
				if (disposMediaObj && disposMediaObj.url) {
					disposMediaObj.name = `DP_${this.form.maintenanceId}_disposal_${i}.jpg`;
					disposMediaObj.longitude = this.currLocation ? this.currLocation.lng : 0;
					disposMediaObj.latitude = this.currLocation ? this.currLocation.lat : 0;
					this.setDisposeMediaObj(i, disposMediaObj);
					this.form.disposals.push(ImageUtil.createMediaFile(disposMediaObj));
				}
			}

			if (this.clientApprovalMediaObj.url) {
				this.clientApprovalMediaObj.name = `CAF_${this.form.maintenanceId}.jpg`;
				this.clientApprovalMediaObj.longitude = this.currLocation ? this.currLocation.lng : 0;
				this.clientApprovalMediaObj.latitude = this.currLocation ? this.currLocation.lat : 0;
				this.form.clientApprovalForm = ImageUtil.createMediaFile(this.clientApprovalMediaObj);
			}

			if (this.capexMediaObj.url) {
				this.capexMediaObj.name = `CP_${this.form.maintenanceId}.jpg`;
				this.capexMediaObj.longitude = this.currLocation ? this.currLocation.lng : 0;
				this.capexMediaObj.latitude = this.currLocation ? this.currLocation.lat : 0;
				this.form.capexForm = ImageUtil.createMediaFile(this.capexMediaObj);
			}
		},

		returnToMaintenanceMain() {
			this.params.fromAddMaintenance = true;

			this.$store.dispatch('setMaintenanceParams', this.params);
			this.$store.dispatch('setCurrentMaintenance', {});

			if (!this.isSuperAdmin) {
				this.$router.push({ path: '/maintenance' });
			} else {
				this.$router.push({ path: '/admin/admin-maintenance' });
			}
		},

		onUploadingInProgress(index, status, name = '', percentage = 0) {
			this.uploadingState[index].name = name;
			this.uploadingState[index].percentage = percentage;
			this.uploadingState[index].status = status;
		},

		onResetUploadingState(index) {
			this.uploadingState[index].name = '';
			this.uploadingState[index].percentage = 0;
			this.uploadingState[index].status = '';
		},

		onSelectDisposePhoto(evt, index) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG or JPEG format.'
				);
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Damage asset(s) photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.setDisposeMediaObj(index, {
						url: url,
						file: file,
						fbStoragePath: 'images/disposals',
						isNew: true,
					});
				}
			};
			image.src = url;
		},

		onSelectClientApprovalForm(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG or JPEG format.'
				);
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"Client approvel form photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.clientApprovalMediaObj = {
						url: url,
						file: file,
						fbStoragePath: 'images/clientApprovalForms',
						isNew: true,
					};
				}
			};
			image.src = url;
		},

		onSelectCapexForm(evt) {
			const vm = this;
			const file = evt.target.files[0];

			if (!FileUtil.isValidImgFileType(file)) {
				this.$toaster.error(
					'Invalid File Type: Please import a valid company logo in PNG or JPEG format.'
				);
				return;
			}

			const url = URL.createObjectURL(file);
			let dimensions = { w: 0, h: 0 };

			const image = new Image();
			image.onload = function () {
				dimensions.w = image.width;
				dimensions.h = image.height;

				if (dimensions.w > 1000 || dimensions.h > 1000) {
					vm.$toaster.warning(
						"CAPEX form photo's width and height shouldn't be greater than 1000 pixels"
					);
				} else {
					vm.capexMediaObj = {
						url: url,
						file: file,
						fbStoragePath: 'images/capexForms',
						isNew: true,
					};
				}
			};
			image.src = url;
		},

		onRemoveDisposePhoto(index) {
			this.setDisposeMediaObj(index, {});
			this.$refs[`assets-for-disposal-file-input-${index}`].reset();
		},

		onRemoveClientForm(index) {
			this.clientApprovalMediaObj = {};
			this.$refs['client-form-file-input'].reset();
		},

		onRemoveCapexForm(index) {
			this.capexMediaObj = {};
			this.$refs['capex-form-file-input'].reset();
		},

		onReset() {
			/* Reset our form values */

			this.form.maintenanceId = '';
			this.form.dateCreated = DateUtil.getCurrentTimestamp();
			this.form.company = '';
			this.form.companyId = '';
			this.form.storageLocation = '';
			this.form.storageLocationId = '';
			this.form.assetType = '';
			this.form.assetTypeId = '';
			this.form.repairCount = 0;
			this.form.disposedCount = 0;
			this.form.repairedBatches = [];
			this.form.status = 'Open';
			this.form.description = '';
			this.form.createdBy = '';
			this.form.clientApprovalForm = null;
			this.form.capexForm = null;
			this.form.approvalFormRemarks = '';

			this.selCompany = config.companyDefaultValue;
			this.selStorageLocation = config.storageLocationDefaultValue;
			this.selAssetType = config.assetTypeDefaultValue;

			// Set default source company
			this.selSourceCompany = this.isSuperAdmin
				? config.companyDefaultValue
				: DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);

			// reset photo upload state
			for (let i = 0; i < 5; i++) {
				this.onResetUploadingState(i);
			}

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},

		getDisposeMediaObj(index) {
			const disposeMediaObjs = [this.disposeMedia1Obj, this.disposeMedia2Obj, this.disposeMedia3Obj];
			return disposeMediaObjs[index];
		},

		setDisposeMediaObj(index, value) {
			const disposeMediaObjs = [this.disposeMedia1Obj, this.disposeMedia2Obj, this.disposeMedia3Obj];
			disposeMediaObjs[index] = value;
			[this.disposeMedia1Obj, this.disposeMedia2Obj, this.disposeMedia3Obj] = disposeMediaObjs;
		}
	},
};
</script>

<style scoped>
.accordion-title {
	color: white !important;
}

.repair-count {
	font-size: 5em;
	/* font-weight: bold; */
}
</style>
